import React, { useState } from 'react';
import './ItemCard.css';

function ItemCard({ item }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="item-card">
      <img src={item.cover_url} alt={item.title} className="item-image" />
      <div className="item-info">
      <div className="item-title">{item.title}</div>
        <p><strong>Price:</strong> {item.currency}{item.price}</p>
        <p><strong>总售数量:</strong> {item.sold_quantity}</p>
        <p><strong>昨日:</strong> {item.yesterday_sold_quantity}</p>
        <p><strong>近7天:</strong> {item.last_7_days_sold_quantity}</p>
        <p><strong>近30天:</strong> {item.last_30_days_sold_quantity}</p>
        <p><strong></strong> {item.tags}</p>
        {item.mall && (
          <div className="mall-info">
            <p>
              <strong>Mall:</strong>
              <a href={`https://www.temu.com/-m-${item.mall.id}.html`} target="_blank" rel="noopener noreferrer">
              {item.mall.name}
              </a>
            </p>
            <p><strong>Mall Score:</strong> {item.mall.score}</p>
          </div>
        )}
        <button onClick={() => setShowDetails(!showDetails)}>Show Details</button>
        {showDetails && (
          <div className="item-details">
            {/* https://www.temu.com/-m-1276220879.html */}
            <p><strong>建议核价:</strong> ¥{(item.price * 7.1 / 2.2).toFixed(2)}</p>
            <p><strong>Market Price:</strong> {item.currency}{item.market_price}</p>
            <p><strong>Score:</strong> {item.score}</p>
            <p><strong>Comments:</strong> {item.comment_num_tips}</p>
            {/* 其他详细信息 */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemCard;
