import React, { useState, useEffect } from 'react';
import ItemList from './components/ItemList';
import './App.css';

function App() {
  const [items, setItems] = useState([]);
  const [malls, setMalls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const itemsResponse = await fetch('/items.json');
      const mallsResponse = await fetch('/mall.json');
      const itemsData = await itemsResponse.json();
      const mallsData = await mallsResponse.json();
      
      // 关联mall信息到item
      const mallMap = {};
      mallsData.RECORDS.forEach(mall => {
        mallMap[mall.id] = mall;
      });

      const combinedData = itemsData.RECORDS.map(item => {
        return {
          ...item,
          mall: mallMap[item.mall_id] || null
        };
      });

      // 根据yesterday_sold_quantity排序
      combinedData.sort((a, b) => b.yesterday_sold_quantity - a.yesterday_sold_quantity);
      
      setItems(combinedData);
    };
    
    fetchData();
  }, []);

  return (
    <div className="App">
      <h1>Product List</h1>
      <ItemList items={items} />
    </div>
  );
}

export default App;
