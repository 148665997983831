import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import ItemCard from './ItemCard';
import './ItemList.css';

const ItemList = () => {
  const [items, setItems] = useState([]);
  const [malls, setMalls] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchItems = async () => {
      const itemsResponse = await fetch('/items.json');
      const itemsData = await itemsResponse.json();

      const mallsResponse = await fetch('/mall.json');
      const mallsData = await mallsResponse.json();

      // 将商品与商店数据进行关联
      const itemsWithMall = itemsData.RECORDS.map(item => {
        const mall = mallsData.RECORDS.find(mall => mall.id === item.mall_id);
        return { ...item, mall };
      });

      const sortedData = itemsWithMall.sort((a, b) => b.yesterday_sold_quantity - a.yesterday_sold_quantity);
      setItems(sortedData);
    };

    fetchItems();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = items.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="item-list">
        {currentItems.map((item) => (
          <ItemCard key={item.id} item={item} />
        ))}
      </div>
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        breakLabel={"..."}
        pageCount={Math.ceil(items.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </>
  );
};

export default ItemList;
